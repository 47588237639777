import React from "react";
import { css } from "util/format_helpers";
import styles from "./styled_button.module.css";

export function StyledButton({
  children,
  onClick,
  className,
  isActive,
  dataTestId,
  defaultButton,
  disabled,
  href,
  variant = "primary",
}) {
  const activeClass = isActive && !disabled ? styles.active : "";
  const defaultButtonClass =
    defaultButton && !disabled ? styles.defaultButton : "";

  if (href) {
    return (
      <a
        href={href}
        className={css(
          "h3",
          "new-styles",
          styles.btn,
          activeClass,
          defaultButtonClass,
          className,
        )}
        disabled={disabled}
        data-variant={variant}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      className={css(
        "h3",
        styles.btn,
        activeClass,
        defaultButtonClass,
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      data-variant={variant}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
}
